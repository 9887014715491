<template>
  <div :class="setting.name + '-content'">
    <label v-if="setting.name == 'enabled'" class="titles" :for="setting.name">{{ $t('widgets.variation') }}:</label>
    <div class="switch-container">
      <p class="switch" v-if="setting.name == 'enabled'">{{ $t('widget.off') }}</p>
      <input class="checkbox" :disabled="readOnly" v-model="value" type="checkbox" :id="setting.name" />
      <p class="switch" v-if="setting.name == 'enabled'">{{ $t('widget.on') }}</p>
    </div>
    <label v-if="setting.name == 'allowAnyCrypto'" class="description" :for="setting.name">{{ $t('widget.allow') }}</label>
  </div>
</template>
  
<script>
import { SettingBool } from "../../code/classes/SettingBool";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingBool },
  },
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
};
</script>
  
<style scoped lang="scss">
.allowAnyCrypto-content {
  display: flex;
  align-items: center;
  position: relative;
  width: max-content;
  left: 51%;
  top: 31px;

  .checkbox {
    cursor: pointer;
    margin: 0;
    margin-right: 8px;
    border-radius: 4px;
    border: 1px solid rgb(133, 133, 133);
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    height: 17px;
    width: 17px;
  }

  .checkbox:checked {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='17' height='17' rx='4' fill='url(%23paint0_linear_2577_118319)'/%3E%3Cpath d='M12.4665 5.77344L7.01237 11.2276L4.5332 8.74844' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2577_118319' x1='0.171965' y1='9.75871' x2='13.9783' y2='9.75871' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
  }

  .description {
    color: #FFF;
    font-family: Gilroy-Regular;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
}

.enabled-content {
  display: flex;
  align-items: center;
  margin-top: 14px;

  .titles {
    width: 135px;
    margin-right: 44px;
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .switch-container {
      display: flex;
      align-items: center;
    }
  .switch {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
  }

  .checkbox {
    position: relative;
    margin: 0px 20px;
    width: 56px;
    height: 25px;
    border-radius: 25px;
    background: #A0AEC0;
    appearance: none;
    transition: 0.3s;
  }

  .checkbox:checked {
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
  }

  .checkbox::before {
    content: '';
    position: absolute;
    top: 2.78px;
    left: 2.8px;
    width: 19.6px;
    height: 19.444px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    transition: 0.3s;
  }

  .checkbox:checked:before {
    left: 33.6px;
  }
}

@media (min-width:1800px) {
  .allowAnyCrypto-content {
    left: 35%;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {

  .allowAnyCrypto-content {
    left: 83.5%;
  }

  .enabled-content {
    .titles {
      margin-right: 26px;
      font-size: 14px;
    }
  }
}

@media (max-width: 680px) {
  .allowAnyCrypto-content {
    left: 0;
    top: 100px
  }

  .enabled-content {
    flex-direction: column;
    align-items: flex-start;

    .titles {
      font-size: 12px;
      margin-right: 0;
      margin-bottom: 10px;
    }
    .switch {
      font-size: 14px;
    }
  }
}
</style>
  