<template>
  <div class="manage-variation-root">
    <p class="title">{{ $t('widget.title') }}</p>
    <div class="list">

      <Proxy :class="setting.name" :setting="setting" :variationType="variation.type"
        v-for="setting in variation.allSettings" v-bind:key="setting.displayName" />

    </div>
    <div class="controls">
      <span class="controls-group">
        <button class="cancel" :class="{ 'disabled': variation.isSaving }" @click="Cancel">{{ $t('buttons.cancel')
        }}</button>
        <button class="button btn" :class="{ 'disabled': variation.isSaving || !variation.dirty || !variation.valid }"
          @click="Save">
          <i class="btn-icon"></i>
          <span>{{ $t('buttons.save') }}</span>
        </button>
      </span>
    </div>
    <loader v-if="variation.isSaving" />
  </div>
</template>
  
<script>
import { NotificationVariationBase } from "../../code/classes/NotificationVariationBase";
import Proxy from "@/components/widgets/proxy.vue";
import loader from '@/components/dashboard/loader.vue';

export default {

  components: {
    Proxy,
    loader,
  },

  watch: {
    "variation.groupIndexSetting.actualValue"(value) {
      this.$emit('isSaved', value);
    },
  },

  props: {
    variation: { type: NotificationVariationBase, default: undefined },
  },

  methods: {
    Save() {
      this.variation?.Save();
    },

    Cancel() {
      if (this.variation.alertDurationSecondsSetting) {
        this.variation.alertDurationSecondsSetting.pendingValue = this.variation.alertDurationSecondsSetting.actualValue

      }
      if (this.variation.textDelaySecondsSetting) {
        this.variation.textDelaySecondsSetting.pendingValue = this.variation.textDelaySecondsSetting.actualValue

      }
      if (this.variation.textDurationSecondsSetting) {
        this.variation.textDurationSecondsSetting.pendingValue = this.variation.textDurationSecondsSetting.actualValue

      }
      if (this.variation.chromaColorSetting) {
        this.variation.chromaColorSetting.pendingValue = this.variation.chromaColorSetting.actualValue

      }
      if (this.variation.allowAnyCrypto) {
        this.variation.allowAnyCrypto.pendingValue = this.variation.allowAnyCrypto.actualValue

      }
      if (this.variation.displayNameSetting) {
        this.variation.displayNameSetting.pendingValue = this.variation.displayNameSetting.actualValue
      }
      if (this.variation.fiatMinValue) {
        this.variation.fiatMinValue.pendingValue = this.variation.fiatMinValue.actualValue

      }
      if (this.variation.fiat) {
        this.variation.fiat.pendingValue = this.variation.fiat.actualValue

      }
      if (this.variation.groupIndexSetting) {
        this.variation.groupIndexSetting.pendingValue = this.variation.groupIndexSetting.actualValue
      }
      if (this.variation.enabledSetting) {
        this.variation.enabledSetting.pendingValue = this.variation.enabledSetting.actualValue
      }
    }
  },

  Delete() {
    this.variation?.Delete();
  },

};
</script>
  
<style scoped lang="scss">
.disabled {
  opacity: 0.5;
  pointer-events: none;
  user-select: none;
}

.manage-variation-root {
  position: relative;
}

.title {
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
}

.list {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin: 22px 0px 60px;
}

.displayName {
  order: 1;
  margin-bottom: 14px;
}

.groupIndex {
  order: 2;
  margin-bottom: 14px;
}

.alertDurationSeconds {
  order: 3;
}

.textDelaySeconds {
  order: 4;
}

.textDurationSeconds {
  order: 5;
}

.chromaColor {
  order: 6;
}

.allowedCryptoTickers,
.allowAnyCrypto {
  order: 7;
}

.fiatMinValue,
.fiat {
  order: 8;
}

.allowAnyCrypto,
.fiatMinValue {
  height: 14px;
}

.enabled {
  order: 9;
}

.controls {
  display: flex;
  justify-content: flex-end;
}

.controls-group {
  display: flex;
  height: 44px;
  gap: 24px;
}

.button {
  display: flex;
  width: 150px;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

.btn-icon {
  width: 16px;
  height: 16px;
  margin-right: 14px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M2 8.00002L6.00028 12L14 4' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}


.cancel {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 100%;
  border-radius: 41px;
  border: 1px solid #FFF;
  background: rgba(13, 11, 23, 0.6);
  color: #FFF;
  text-align: center;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  text-transform: uppercase;
}

@media (max-width: 680px) {
  .title {
    font-size: 14px;
  }

  .fiat {
    margin-top: 20px;
  }

  .enabled {
    margin-top: 45px;
  }

  .controls-group {
    flex-direction: column;
    height: max-content;
    width: 100%;
    gap: 14px
  }

  .cancel,
  .button {
    width: 100%;
    height: 40px;
  }
}
</style>
  