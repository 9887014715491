<template>
    <div class="setting-flags-root">
        <span class="titles">{{ $t('widget.token') }}</span>

        <div class="select-content">
            <span class="select" @click="openDropdown()">
                {{ $t('widget.tokenPlaceholder') }}
                <i class="select-icon"></i>
            </span>

            <ul v-if="dropDownIsOpened" class="dropdown">
                <li class="dropdown-list" v-for="flag in setting.value" :key="flag.name">
                    <label class="label" :for="flag.name">{{ flag.name }}</label>
                    <input class="checkbox" :disabled="readOnly" type="checkbox" :id="flag.name" v-model="flag.value" />
                </li>
            </ul>
        </div>
    </div>
</template>
  
<script>
import { SettingFlags } from "../../code/classes/SettingFlags";

export default {
    props: {
        readOnly: { type: Boolean, default: false },
        setting: { type: SettingFlags },
    },

    data: () => ({
        dropDownIsOpened: false
    }),

    watch: {
        dropDownIsOpened(value) {
            if (value) {
                document.addEventListener('click', event => {
                    if ((!document.querySelector('.setting-flags-root')?.contains(event.target))) {
                        this.dropDownIsOpened = false
                    }
                });
            }
        },

    },

    methods: {
        openDropdown() {
            if (!this.dropDownIsOpened) {
                this.dropDownIsOpened = true
            } else {
                this.dropDownIsOpened = false
            }
        }
    }
};
</script>
  
<style scoped lang="scss">
.setting-flags-root {
    display: flex;
    align-items: center;
    width: max-content;
}

.titles {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 135px;
    margin-right: 44px;
}

.select-content {
    position: relative;
}

.select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 240px;
    height: 44px;
    padding: 0px 20px;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.1);
    cursor: pointer;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.select-icon {
    width: 14px;
    height: 6px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='none'%3E%3Cpath d='M15 1L8 7L1 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dropdown {
    position: absolute;
    top: 54px;
    left: 0;
    z-index: 1;
    border-radius: 12px;
    background: #2B263B;
    width: 280px;
    padding: 14px 0px;
}

.dropdown-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 28px;
    padding: 0px 20px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
}

.dropdown-list:hover {
    background: linear-gradient(90deg, rgba(213, 116, 222, 0.3) 1.01%, rgba(142, 84, 233, 0.3) 82.23%);
    box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

}

.label {
    width: 100%;
    cursor: pointer;
}

.checkbox {
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid rgb(133, 133, 133);
    background: transparent;
    appearance: none;
    height: 17px;
    width: 17px;
}

.checkbox:checked {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='17' height='17' rx='4' fill='url(%23paint0_linear_2577_118319)'/%3E%3Cpath d='M12.4665 5.77344L7.01237 11.2276L4.5332 8.74844' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2577_118319' x1='0.171965' y1='9.75871' x2='13.9783' y2='9.75871' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23D574DE'/%3E%3Cstop offset='1' stop-color='%238E54E9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}

@media (min-width: 0px) and (max-width: 1290px) {
  .titles {
    margin-right: 26px;
    font-size: 14px;
  }
}

@media (max-width: 680px) {
    .setting-flags-root {
        flex-direction: column;
        align-items: flex-start;
    }

    .titles {
        font-size: 12px;
        margin-right: 0;
        margin-bottom: 10px;
    }

    .select {
        width: 214px;
    }

    .dropdown {
        width: 254px;
    }
}
</style>
  