<template>
  <div class="amount-content">
    <input class="amount" :disabled="readOnly" v-model="value" type="number" :id="setting.name" />
  </div>
</template>
  
<script>
import { SettingNumber } from "../../code/classes/SettingNumber";

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingNumber },
  },
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
};
</script>
  
<style scoped lang="scss">
.amount-content {
  position: relative;
  width: max-content;
  left: 51%;
  top: 18px;
}

.amount {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 44px;
  padding: 0;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  color: #FFF;
  text-align: center;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  border: none;
}

.amount::-webkit-outer-spin-button,
.amount::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (min-width:1800px) {
  .amount-content {
    left: 35%;
  }
}

@media (min-width: 0px) and (max-width: 1290px) {
  .amount-content {
    left: 75.5%;
  }

  .amount {
    width: 100px;
  }
}

@media (max-width: 680px) {
  .amount-content {
    left: 0;
    top: 120px
  }
}
</style>
  