<template>
  <div class="color-container" v-if="variationType === 'default'">
    <label class="titles" :for="setting.name">{{ $t('widget.color') }}</label>
    <span class="input-container">
      <input :disabled="readOnly" v-model="value" type="text" :id="setting.name" :placeholder="placeholder" @input="openPallete"/>
      <figure ref="preview" class="color-preview" @click="openPallete" />
      <!-- {{ setting.error }} -->
    </span>
    <sketch v-if="palleteIsOpened" v-model="color"></sketch>
  </div>
</template>
  
<script>
import { SettingColor } from "../../code/classes/SettingColor";
import { Sketch } from 'vue-color';
import i18n from '../../i18n';

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingColor },
    variationType: { type: String },
  },

  components: { Sketch },
  data: () => ({
    color: '#000',
    palleteIsOpened: false,
    placeholder: i18n.t('widget.colorError')
  }),

  watch: {
    currentColor() {
      this.value = this.currentColor
      this.color.hex8 = this.value
    },

    "setting.value"() {
      this.updateColor();
    },

    palleteIsOpened: function (value) {
      if (value) {
        document.addEventListener('click', event => {
          if ((!document.querySelector('.chromaColor')?.contains(event.target))
          && (!document.querySelector('.vc-sketch')?.contains(event.target))) {
            this.palleteIsOpened = false
          }
        });

      }
    },
  },

  mounted() {
    this.updateColor();
  },

  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },

    currentColor() {
      return this.color?.hex8 ?? '#000'
    },
  },

  methods: {
    updateColor() {
      if (!this.setting.error && this.variationType == 'default') {
        this.$refs.preview.style.background = this.setting.value;
      }
    },

    openPallete() {
      if (!this.palleteIsOpened) {
        this.palleteIsOpened = true
      } else {
        this.palleteIsOpened = false
      }
    }
  },
};
</script>
  
<style scoped lang="scss">
.color-container {
  margin-top: 28px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.titles {
  width: 135px;
  margin-right: 44px;
  color: #A0AEC0;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

#chromaColor {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  width: 254px;
  height: 44px;
  padding: 0px 6px 0px 20px;
  border: none;
  color: #FFF;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

.color-preview {
  position: absolute;
  cursor: pointer;
  right: 6px;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin: 0;
  border: 1px solid #fff;
  border-radius: 11px;
}


@media (min-width: 0px) and (max-width: 1290px) {
  .titles {
    font-size: 14px;
    margin-right: 26px;
  }
}

@media (max-width: 680px) {
  .titles {
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 10px;
  }

  .color-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 24px;
  }

  #chromaColor {
    width: 228px;
  }
}
</style>
  