<template>
  <div class="select-container">
    <label class="titles">{{ settingName }}</label>
    <div class="select-content">
      <span class="select" :class="setting.name" @click="openDropdown()">
        {{ setting.name == 'groupIndex' ? groupLocale : '' }} {{ setting.pendingValue || setting.actualValue }}
        <i class="select-icon"></i>
      </span>

      <ul v-if="dropDownIsOpened" :id="setting.name" class="dropdown">
        <li class="dropdown-list" v-for="option in setting.options" v-bind:key="option"
          :disabled="setting.value === option" @click="select(option)">
          {{ setting.name == 'groupIndex' ? groupLocale : '' }} {{ option }}
        </li>
      </ul>
      
    </div>
  </div>
</template>
  
<script>
import { SettingChoice } from "../../code/classes/SettingChoice";
import i18n from '../../i18n';

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingChoice },
  },

  data: () => ({
    dropDownIsOpened: false,
    groupLocale:  i18n.t('widget.groupLocale')
  }),

  watch: {
    dropDownIsOpened(value) {
      if (value) {
        document.addEventListener('click', event => {
          if ((!document.querySelector('.select')?.contains(event.target))
          && (!document.querySelector('.select.fiat')?.contains(event.target))) {
           this.dropDownIsOpened = false
          }
        });
      }
    },

  },

  computed: {
    settingName() {
      let settingName = this.setting.name;
      if (settingName == 'groupIndex') {
        settingName = i18n.t('widget.group')
      } else if (settingName == 'fiat') {
        settingName = i18n.t('widget.minAmount')
      } else {
        settingName = ''
      }
      return settingName
    }
  },

  methods: {
    select(option) {
      this.setting.SetValue(option);
    },

    openDropdown() {
      if(!this.dropDownIsOpened){
        this.dropDownIsOpened = true
      }else {
        this.dropDownIsOpened = false
      }
    }
  },

}
</script>
  
<style lang="scss" scoped>
.select-container {
  display: flex;
  align-items: center;
}

.titles {
  color: #A0AEC0;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 135px;
  margin-right: 44px;
}

.select-content {
  position: relative;
}

.select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 240px;
  height: 44px;
  padding: 0px 20px;
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  cursor: pointer;
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
}

.select-icon {
  width: 14px;
  height: 6px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='8' viewBox='0 0 16 8' fill='none'%3E%3Cpath d='M15 1L8 7L1 1' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}

.dropdown {
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 1;
  border-radius: 12px;
  background: #2B263B;
  width: 280px;
  padding: 14px 0px;
}

.dropdown-list {
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 28px;
  padding: 0px 20px;
  color: #FFF;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.dropdown-list:hover {
  background: linear-gradient(90deg, rgba(213, 116, 222, 0.3) 1.01%, rgba(142, 84, 233, 0.3) 82.23%);
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);

}

.fiat,
#fiat .dropdown-list {
  text-transform: uppercase;
}

@media (min-width: 0px) and (max-width: 1290px) {
  .titles {
    margin-right: 26px;
    font-size: 14px;
  }
}

@media (max-width: 680px) {
  .select-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .titles {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 12px
  }

  .select  {
    width: 214px;
  }

  .dropdown {
    width: 254px;
  }
}
</style>
  