<template>
    <div>
        <div class="slider-content">
            <label class="titles" :for="setting.name">{{ settingName }}</label>

            <input :disabled="readOnly" v-model="value" type="range" ref="slider" :id="setting.name" :min="setting.minValue"
                :max="setting.maxValue" :step="setting.step" />

            <span class="limit-content">
                <input class="limitInput" type="number" :min="setting.minValue" :max="setting.maxValue" v-model="value"
                    :step="setting.step">
                <p class="second">{{ $t('widget.second') }}</p>
            </span>

        </div>
    </div>
</template>
  
<script>
import { SettingSlider } from "../../code/classes/SettingSlider";
import i18n from '../../i18n';

export default {
    props: {
        readOnly: { type: Boolean, default: false },
        setting: { type: SettingSlider },
    },
    watch: {
        value() {
            this.updateTrackColor()
        }
    },

    mounted() {
        this.updateTrackColor()
    },
    
    computed: {
        value: {
            get() {
                return this.setting.value;
            },
            set(value) {
                this.setting.SetValue(value);
            },
        },

        settingName() {
            let settingName = this.setting.displayName;
            if (settingName == 'variationSetting_alertDurationSeconds') {
                settingName = i18n.t('widget.alert')
            } else if (settingName == 'variationSetting_textDelaySeconds') {
                settingName = i18n.t('widget.textDelay')
            } else if (settingName == 'variationSetting_textDurationSeconds') {
                settingName = i18n.t('widget.textDuration')
            } else {
                settingName = ''
            }
            return settingName
        }
    },

    methods: {
        updateTrackColor() {
            const slider = this.$refs.slider;
            const trackProgress = (slider.value - slider.min) / (slider.max - slider.min);
            console.log(trackProgress)
            const trackColor = `linear-gradient(90deg, #D574DE ${trackProgress * 20}%, #432D64 ${trackProgress * 82.23}%)`;

            slider.style.background = trackColor;
        },
    }
};
</script>
<style scoped lang="scss">
.titles {
    color: #A0AEC0;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 106px;
}

.slider-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
}

.textDurationSeconds .slider-content {
    margin-bottom: 0;
}

#alertDurationSeconds,
#textDelaySeconds,
#textDurationSeconds {
    width: 590px;
    position: relative;
    height: 15px;
    border-radius: 100px;
    -webkit-appearance: none;
    appearance: none;
    background: #432D64;
}

#alertDurationSeconds::-webkit-slider-runnable-track,
#textDelaySeconds::-webkit-slider-runnable-track,
#textDurationSeconds::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 100px;
}

#alertDurationSeconds::-moz-range-track,
#textDelaySeconds::-moz-range-track,
#textDurationSeconds::-moz-range-track {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 100%;
    border-radius: 100px;
}

#alertDurationSeconds::-webkit-slider-thumb,
#textDelaySeconds::-webkit-slider-thumb,
#textDurationSeconds::-webkit-slider-thumb {
    position: relative;
    -webkit-appearance: none;
    appearance: none;
    outline: 9px solid #FFF;
    top: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
    cursor: pointer;
}

#alertDurationSeconds,
#textDelaySeconds::-moz-range-thumb,
#textDurationSeconds::-moz-range-thumb {
    outline: 9px solid #FFF;
    top: 2px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: linear-gradient(90deg, #D574DE 1.01%, #8E54E9 82.23%);
    cursor: pointer;
}

.limit-content {
    position: relative;
}

.limitInput {
    position: relative;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 60px;
    height: 44px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    border: none;
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.10);
}

.second {
    position: absolute;
    top: 12px;
    right: 20px;
    color: #FFF;
    font-family: Gilroy-Medium;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
}

.limitInput::-webkit-outer-spin-button,
.limitInput::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

@media (min-width:1800px) {

    #alertDurationSeconds,
    #textDelaySeconds,
    #textDurationSeconds {
        width: 1031px;
    }
}

@media (min-width: 0px) and (max-width: 1290px) {

    .titles {
        font-size: 14px;
    }

    #alertDurationSeconds,
    #textDelaySeconds,
    #textDurationSeconds {
        width: 280px;
    }
}

@media (max-width: 680px) {

    .slider-content {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 24px;
    }


    #alertDurationSeconds,
    #textDelaySeconds,
    #textDurationSeconds {
        width: 254px;
    }

    .titles {
        width: 100%;
        font-size: 12px;
        margin-bottom: 15px;
    }

    .limit-content {
        margin-top: 36px;
    }

    .limitInput {
        height: 40px;
    }

}
</style>
  