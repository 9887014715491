<template>
  <div class="name-container">
    <label class="titles" :for="setting.name">{{ $t('widget.name') }}</label>
    <input :disabled="readOnly" v-model="value" type="text" :id="setting.name" :placeholder="placeholder" />
  </div>
</template>
  
<script>
import { SettingString } from "../../code/classes/SettingString";
import i18n from '../../i18n';

export default {
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingString },
  },
  data:() => ({
    placeholder: i18n.t('widget.nameError')
  }),
  computed: {
    value: {
      get() {
        return this.setting.value;
      },
      set(value) {
        this.setting.SetValue(value);
      },
    },
  },
};
</script>
  
<style scoped lang="scss">
.name-container {
  display: flex;
  align-items: center;
}

.titles {
  width: 135px;
  margin-right: 44px;
  color: #A0AEC0;
  font-family: Gilroy-Medium;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#displayName {
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.10);
  width: 254px;
  height: 44px;
  padding: 0px 6px 0px 20px;
  border: none;
  color: #FFF;
  font-family: Gilroy-Regular;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
}

@media (min-width: 0px) and (max-width: 1290px) {
  .titles {
    font-size: 14px;
    margin-right: 26px;
  }
}

@media (max-width: 680px) {

  .name-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .titles {
    font-size: 12px;
    margin-right: 0;
    margin-bottom: 10px;
  }
  
  #displayName {
    width: 228px;
  }
}
</style>
  