<template>
  <div :class="classes" v-if="!setting?.hidden">
    <SettingBoolWidget v-if="setting?.type === 'SettingBool'" :setting="setting" :readOnly="readOnly" />
    <SettingChoiceWidget v-else-if="setting?.type === 'SettingChoice'" :setting="setting" :readOnly="readOnly" />
    <SettingColorWidget v-else-if="setting?.type === 'SettingColor'" :setting="setting" :variationType="variationType" :readOnly="readOnly" />
    <SettingNumberWidget v-else-if="setting?.type === 'SettingNumber'" :setting="setting" :readOnly="readOnly" />
    <SettingSliderWidget v-else-if="setting?.type === 'SettingSlider'" :setting="setting" :readOnly="readOnly" />
    <SettingStringWidget v-else-if="setting?.type === 'SettingString'" :setting="setting" :readOnly="readOnly" />
    <SettingFlagsWidget v-else-if="setting?.type === 'SettingFlags'" :setting="setting" :readOnly="readOnly" />
  </div>
</template>

<script>
import { SettingBase } from "../../code/classes/SettingBase";
import SettingBoolWidget from "./settingBoolWidget.vue";
import SettingChoiceWidget from "./settingChoiceWidget.vue";
import SettingColorWidget from "./settingColorWidget.vue"
import SettingFlagsWidget from "./settingFlagsWidget.vue";
import SettingNumberWidget from "./settingNumberWidget.vue";
import SettingSliderWidget from "./settingSliderWidget.vue";
import SettingStringWidget from "./settingStringWidget.vue";

export default {
  components: {
    SettingBoolWidget,
    SettingChoiceWidget,
    SettingColorWidget,
    SettingFlagsWidget,
    SettingNumberWidget,
    SettingSliderWidget,
    SettingStringWidget,
  },
  props: {
    readOnly: { type: Boolean, default: false },
    setting: { type: SettingBase },
    variationType: { type: String },
  },
  computed: {
    classes() {
      const classes = [];
      if (this.setting?.uninitialized) classes.push("uninitialized");
      if (this.setting?.disabled) classes.push("disabled");
      return classes.join(" ");
    },
  },
};
</script>

<style scoped lang="scss">
.uninitialized,
.disabled {
  opacity: 0.5;
  pointer-events: none;
}
</style>
